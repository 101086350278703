<template>
	<section class="content">
		<form class="row" role="form" @submit.prevent="createVoucher">
			<div class="col-12 position-static" style="top: 5rem;z-index: 1000">
                <div class="row justify-content-end">
                    <div class="col-auto">
                        <button type="submit" @click="forms.save = 'draft'" class="btn btn-outline-primary"><i class="fi fi-rr-file-download"></i> Simpan ke draft</button>
                    </div>
                    <div class="col-auto">
                        <button type="submit" @click="forms.save = 'publish'" class="btn btn-primary"><i class="fi fi-rr-plus"></i> Publish voucher</button>
                    </div>
                </div>
            </div>
			<div class="col-lg-6 mt-3">
				<div class="card border rounded mb-3">
					<div class="card-body pb-1">
						<div class="border-bottom mb-3">
							<label class="h5">Detail Voucher</label>
						</div>
						<div class="form-group">
							<label>Kode Voucher</label>
							<div class="row" style="row-gap: 15px;">
								<div class="col">
									<div class="form-control h-100" :class="{ 'border-primary' : forms.generate === true }" :style="{ backgroundColor: forms.generate === true ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<div class="icheck-material-orange w-100">
											<input type="radio" @click="resetCode" id="random" name="voucher-generate" v-model="forms.generate" :value="true" required />
											<label for="random" class="form-check-label w-100">Random generate</label>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="form-control h-100" :class="{ 'border-primary' : forms.generate === false }" :style="{ backgroundColor: forms.generate === false ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<div class="icheck-material-orange w-100">
											<input type="radio" @click="resetCode" id="manual" name="voucher-generate" v-model="forms.generate" :value="false" required />
											<label for="manual" class="form-check-label w-100">Manual generate</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" v-if="forms.generate !== null">
							<div class="form-control bg-light border-light p-3 h-100">
								<div class="row">
									<div class="col-auto" v-if="forms.generate === true">
										<button type="button" @click="generateCode" class="btn btn-primary">Generate</button>
									</div>
									<div class="col">
										<div class="input-group">
											<input type="text" name="voucher-code" v-model="forms.code" @keypress="handleCode" :placeholder="forms.generate === true ? 'Klik generate' : 'Cth: BOSCODPROMOJUNI'" class="form-control border-right-0 bg-white" :class="{ 'is-invalid': errors.code }" required />
											<div class="input-group-append cursor-pointer" ref="btnCopy" @click="copyText(forms.code)" data-toggle="tooltip" data-placement="top" title="Salin ke papan klip">
												<div class="input-group-text rounded-right d-flex align-items-center border-left-0 bg-white" :class="{ 'border-danger': errors.code }">
													<i class="fi fi-rr-copy-alt text-primary"></i>
												</div>
											</div>
										</div>
										<small class="text-danger" v-if="errors.code">Kode sudah terpakai. Masukkan kode lainnya yang belum terpakai</small>
									</div>
									<div class="col-auto" v-if="forms.generate === false">
										<button type="button" @click="errors.code = true" class="btn btn-primary">Simpan</button>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group h-100">
							<label>Deskripsi Voucher</label>
							<textarea name="voucher-description" v-model="forms.description" @change="errors.description = false" placeholder="Masukkan deskripsi voucher" minlength="15" class="form-control h-100" style="resize: none;" :class="{ 'is-invalid': errors.description }" required
								onkeydown="return (event.ctrlKey || event.altKey || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57 && event.shiftKey==true) || (95<event.keyCode && event.keyCode<106) || (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48 && event.shiftKey==true) || (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))">
							</textarea>
							<small class="text-danger" v-if="errors.description">Deskripsi voucher tidak boleh kosong</small>
						</div>

						<div class="form-group">
							<div class="row" style="row-gap: 15px;">
								<div class="col-md-5">
									<label>Tipe Nominal</label>
									<select name="voucher-type" v-model="forms.type" @change="resetType" class="form-control" required>
										<option value="percentage">Percentage (%)</option>
										<option value="fixed">Fixed Amount (Rp)</option>
									</select>
								</div>
								<div class="col-6 col-md-3" v-if="forms.type == 'percentage'">
									<label>Persentase</label>
									<div class="input-group">
										<input type="number" min="1" name="voucher-percentage" v-model="forms.percentage" placeholder="Persentase" class="form-control border-right-0 pr-0" :required="forms.type == 'percentage'" />
										<div class="input-group-append">
											<div class="input-group-text rounded-right d-flex align-items-center border-left-0 bg-white pl-0">
												<i class="fi fi-rr-percentage text-primary"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="col-6 col-md-4" v-if="forms.type == 'percentage'">
									<label>Nominal&nbsp;Maksimal</label>
									<div class="input-group">
										<currency-input name="voucher-nominal" v-model="forms.nominal" currency="IDR" currencyDisplay="narrowSymbol" :precision="0" placeholder="Nominal" class="form-control border-right-0 pr-0" :required="forms.type == 'percentage'" />
										<div class="input-group-append">
											<div class="input-group-text rounded-right d-flex align-items-center border-left-0 bg-white pl-0">
												<i class="fi fi-rr-rupiah-sign text-primary"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-7" v-if="forms.type == 'fixed'">
									<label>Besaran Nominal</label>
									<div class="input-group">
										<currency-input name="voucher-fixed" v-model="forms.fixed" currency="IDR" currencyDisplay="narrowSymbol" :precision="0" placeholder="Nominal" class="form-control border-right-0" :required="forms.type == 'fixed'" />
										<div class="input-group-append">
											<div class="input-group-text rounded-right d-flex align-items-center border-left-0 bg-white">
												<i class="fi fi-rr-rupiah-sign text-primary"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="form-control bg-light border-light p-3 h-100">
								<div class="input-group">
									<input type="text" name="voucher-resume" v-model="forms.resume" :placeholder="'Masukkan ' + (forms.type == 'percentage' ? forms.percentage > 0 ? 'nominal maksimal' : 'nilai persentase' : 'nominal')" class="form-control border-right-0 bg-white" :class="{ 'is-invalid': errors.resume }" required readonly />
									<div class="input-group-append cursor-pointer" ref="btnCopy" @click="copyText(forms.resume)" data-toggle="tooltip" data-placement="top" title="Salin ke papan klip">
										<div class="input-group-text rounded-right d-flex align-items-center border-left-0 bg-white" :class="{ 'border-danger': errors.resume }">
											<i class="fi fi-rr-copy-alt text-primary"></i>
										</div>
									</div>
								</div>
								<small class="text-danger" v-if="errors.resume">Lengkapi dulu nilai dari presentase dan nominal maksimal</small>
							</div>
						</div>

						<div class="form-group h-100">
							<label>Periode Voucher</label>
							<div class="input-group">
								<input type="text" name="voucher-period" ref="period" class="form-control border-right-0" required />
								<div class="input-group-append">
									<div class="input-group-text rounded-right d-flex align-items-center border-left-0 bg-white">
										<i class="fi fi-rr-calendar d-flex"></i>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label>Kuota Voucher</label>
							<div class="row">
								<div class="col-7">
									<select name="voucher-quota" v-model="forms.quota" class="form-control" required>
										<option value="" selected disabled hidden>Pilih opsi kuota</option>
										<option value="limited">Kuota ditentukan</option>
										<option value="unlimited">Kuota unlimited</option>
									</select>
								</div>
								<div class="col-5">
									<input type="number" name="voucher-limit" v-model="forms.limit" :placeholder="forms.quota == '' ? 'Pilih opsi nominal' : forms.quota == 'limited' ? 'Masukkan kuota' : forms.quota == 'unlimited' ? 'Kuota unlimited' : ''" :disabled="forms.quota != 'limited'" class="form-control" :class="{ 'is-invalid': errors.limit && forms.quota == 'limited' }" :required="forms.quota == 'limited'" />
									<small class="text-danger" v-if="errors.limit && forms.quota == 'limited'">Kuota tidak boleh kosong</small>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>

			<div class="col-lg-6 mt-3">
				<div class="card border rounded mb-3">
					<div class="card-body pb-1">
						<div class="border-bottom mb-3">
							<label class="h5">Role Voucher</label>
						</div>
						<div class="form-group">
							<div class="form-control bos-alert-primary d-flex align-items-center h-100">
								<div class="row">
									<div class="col-auto d-flex align-items-center">
										<i class="fi fi-rr-info d-flex align-items-center h5 mb-0"></i>
									</div>
									<div class="col d-flex align-items-center lh-1">
										<div class="text-md">
											Rule voucher digunakan untuk mengatur ketentuan-ketentuan tertentu saat pembuatan voucher
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label>Batas penggunaan voucher</label>
							<select name="voucher-usage" v-model="forms.usage" class="form-control" required>
								<option value="" selected disabled hidden>-- Pilih opsi --</option>
								<option value="daily">Daily fair usage</option>
								<option value="fair">Fair usage</option>
								<option value="user">Per user limit</option>
							</select>
						</div>
						<div class="form-group">
							<div class="form-control bg-light border-light p-3 h-100" v-if="forms.usage != ''">
								<label>Maksimal Voucher</label>
								<input type="number" name="voucher-maximum" v-model="forms.maximum" :min="forms.usage != '' ? 1 : 0" :disabled="forms.usage == 'user'" class="form-control" :required="forms.usage != ''" />
							</div>
						</div>

						<div class="form-group">
							<label>Pembatasan kurir</label>
							<select name="voucher-courier" v-model="forms.courier" @change="resetCourier" class="form-control" required>
								<option value="" selected disabled hidden>-- Pilih opsi --</option>
								<option value="all">Semua kurir</option>
								<option value="custom">Pilih kurir tertentu</option>
							</select>
						</div>
						<div class="form-group">
							<div class="form-control bg-light pt-2 h-100" v-if="forms.courier == 'custom'">
								<div class="row">
									<div class="col-6 col-sm-4 h-100" v-for="(courier) in listCourier">
										<div class="icheck-material-orange w-100">
											<input type="checkbox" :id="courier.prefix" name="voucher-service[]" v-model="forms.service" :value="courier.id" :required="forms.courier == 'custom' && forms.service.length < 1" />
											<label :for="courier.prefix" class="form-check-label text-xs w-100">{{ courier.nama }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label>Minimal nilai transaksi</label>
							<select name="voucher-minimum" v-model="forms.minimum" class="form-control" required>
								<option value="" selected disabled hidden>-- Pilih opsi --</option>
								<option value="none">Tidak ada minimal transaksi</option>
								<option value="value">Dengan nilai COD atau nilai ongkir</option>
							</select>
						</div>
						<div class="form-group">
							<div class="form-control bg-light border-light p-3 h-100" v-if="forms.minimum == 'value'">
								<label>Nilai COD atau Nilai Ongkir</label>
								<currency-input name="voucher-value" v-model="forms.value" currency="IDR" currencyDisplay="narrowSymbol" :precision="0" placeholder="Nilai" class="form-control" :required="forms.minimum == 'value'" />
							</div>
						</div>

						<div class="form-group">
							<label>Area yang berlaku</label>
							<select name="voucher-coverage" v-model="forms.coverage" @change="resetCoverage" class="form-control" required>
								<option value="" selected disabled hidden>-- Pilih opsi --</option>
								<option value="all">Semua area</option>
								<option value="custom">Pilih area tertentu</option>
							</select>
						</div>
						<div class="form-group" v-if="forms.coverage == 'custom'">
							<div class="form-control bg-light border-light p-3 h-100">
								<div class="form-group">
									<label>Asal Pengiriman</label>
                                    <v-select :options="listProvince" :reduce="opt => opt.id" name="voucher-origin" v-model="forms.origin" open-direction="above" placeholder="-- Pilih provinsi --" multiple>
										<template slot="no-options">
											Nama provinsi tidak ditemukan
										</template>
										<template #search="{attributes, events}">
											<input class="vs__search" :required="forms.coverage == 'custom' && forms.origin.length < 1" v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
								<div class="form-group mb-0">
									<label>Tujuan Pengiriman</label>
                                    <v-select :options="listProvince" :reduce="opt => opt.id" name="voucher-destination" v-model="forms.destination" open-direction="above" placeholder="-- Pilih provinsi --" multiple>
										<template slot="no-options">
											<em style="opacity: 0.5">Nama provinsi tidak ditemukan</em>
										</template>
										<template #search="{attributes, events}">
											<input class="vs__search" :required="forms.coverage == 'custom' && forms.destination.length < 1" v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</div>
						</div>
                    </div>
                </div>

				<div class="card border rounded mb-3">
					<div class="card-body pb-1">
						<div class="border-bottom mb-3">
							<label class="h5">Publikasi Voucher</label>
						</div>

						<div class="form-group">
							<label>Publikasi voucher</label>
							<select name="voucher-publicity" v-model="forms.publicity" @change="resetPublicity" class="form-control" required>
								<option value="" selected disabled hidden>-- Pilih opsi --</option>
								<option value="public">Public</option>
								<option value="private">Private</option>
							</select>
						</div>

						<div class="form-group" :hidden="!(forms.publicity == 'private')">
							<div class="form-control bg-light border-light p-3 h-100">
								<div class="form-group">
									<label>Personalisasi voucher</label>
									<div class="row">
										<div class="col-sm">
											<select name="voucher-personality" v-model="forms.personality" @change="resetPersonality" class="form-control" :required="forms.publicity == 'private'">
												<option value="" selected disabled hidden>-- Pilih opsi --</option>
												<option value="shipper">ID Shipper</option>
												<option value="member">Membership</option>
												<option value="join">Tanggal Bergabung</option>
												<option value="shipping">Jumlah Kiriman</option>
											</select>
										</div>

										<div class="col-sm-7" :hidden="!(forms.personality == 'shipper')">
											<v-select :options="listShipper" :reduce="opt => opt.id" ref="shipper" name="voucher-shipper" v-model="selectShipper" @search="searchShipper" open-direction="above" placeholder="Pilih shipper" :required="forms.personality == 'shipper'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching && search.length > 3">
														<em style="opacity: 0.5">Nama shipper {{ search }} tidak ditemukan.</em>
													</template>
													<em v-else style="opacity: 0.5">Ketik nama shipper</em>
												</template>
												<template #search="{attributes, events}">
													<input class="vs__search" :required="forms.personality == 'shipper' && forms.shipper.length < 1" v-bind="attributes" v-on="events" />
												</template>
											</v-select>
										</div>

										<div class="col-sm-7" :hidden="!(forms.personality == 'member')">
											<v-select :options="listMember" :reduce="opt => opt.id" name="voucher-member" v-model="forms.member" open-direction="above" placeholder="Pilih membership" multiple :required="forms.personality == 'member'">
												<template v-slot:no-options="{ search, searching }">
													<em style="opacity: 0.5">Membership {{ search }} tidak tersedia</em>
												</template>
												<template #search="{attributes, events}">
													<input class="vs__search" :required="forms.personality == 'member' && forms.member.length < 1" v-bind="attributes" v-on="events" />
												</template>
											</v-select>
										</div>

										<div class="col-sm-7" :hidden="!(forms.personality == 'join')">
											<div class="input-group">
												<input type="text" name="voucher-join" ref="join" class="form-control border-right-0" :required="forms.personality == 'join'" />
												<div class="input-group-append">
													<div class="input-group-text rounded-right d-flex align-items-center border-left-0 bg-white">
														<i class="fi fi-rr-calendar d-flex"></i>
													</div>
												</div>
											</div>
										</div>

										<div class="col-sm-7" :hidden="!(forms.personality == 'shipping')">
											<input type="number" name="voucher-amount" v-model="forms.amount" placeholder="Masukkan jumlah kiriman" class="form-control" :required="forms.personality == 'shipping'" />
										</div>
									</div>
								</div>

								<div class="form-group" v-if="forms.personality == 'shipper' && forms.shipper.length">
									<div class="form-control p-0 pb-1 h-100">
										<div class="vs__selected-options">
											<div class="vs__selected" v-for="(shipper) in forms.shipper">
												{{ shipper.label }} <button class="vs__deselect" @click="removeShipper(shipper.id)">&#128473;</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </form>
	</section>
</template>
<script>
import { authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import moment from "moment";
import { CurrencyInput } from "vue-currency-input";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "icheck-material";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import $ from "jquery";

export default {
	name: "JurnalGroup",
	data() {
		return {
			loading: false,
			listCourier: [],
			listProvince: [],
			listShipper: [],
			selectShipper: null,
			listMember: [],
            forms: {
				save: null,
                generate: null,
                code: '',
                description: '',
				type: 'percentage',
				percentage: null,
				nominal: null,
				fixed: null,
				resume: null,
				period_start: null,
				period_end: null,
				quota: '',
				limit: null,
				usage: '',
				maximum: null,
				courier: '',
				service: [],
				minimum: '',
				value: null,
				coverage: '',
				origin: [],
				destination: [],
				publicity: '',
				personality: '',
				shipper: [],
				member: [],
				join_start: null,
				join_end: null,
				shipping: null
            },
			errors: {
				code: false,
				description: false,
				resume: false,
				limit: false
			}
		};
	},
	created: function () {
	},
	methods: {
		getImage(file) {
			return require(`../assets/img/${file}.svg`); // the module request
		},
		resetCode: function() {
			this.forms.code = '';
			this.errors.code = false;
		},
		generateCode: function() {
			this.forms.code = 'BOSCODPROMOJULI';
		},
		handleCode: function(evt) {
			if (this.forms.generate === true) {
				evt.preventDefault();
			}
		},
		copyText: function(text) {
			var self = this;
			navigator.clipboard.writeText(text).then(function() {
				$(self.$refs.btnCopy).attr('title','Berhasil menyalin').tooltip('_fixTitle').tooltip('show').attr('title','Salin').tooltip('_fixTitle')
			}, function(err) {
				console.error(err);
			});
		},
		resetType: function() {
			this.forms.resume = null;
			this.forms.percentage = null;
			this.forms.nominal = null;
			this.forms.fixed = null;
			this.errors.resume = false;
		},
		resetCourier: function() {
			this.forms.service = [];
		},
		resetCoverage: function() {
			this.forms.origin = [];
			this.forms.destination = [];
		},
		resetPublicity: function() {
			this.forms.personality = '';
			this.resetPersonality;
		},
		resetPersonality: function() {
			this.forms.shipper = [];
			this.forms.member = [];
			this.forms.join_start = null;
			this.forms.join_end = null;
			this.forms.shipping = null;
		},
		searchShipper: function(search, loading) {
			if (search.length > 3 && this.loading === false) {
				loading(true);
				this.loading = true;
				authFetch(`/order/shipper/?search=${escape(search)}`)
				.then((res) => {
					if (res.status !== 200) {
						loading(false);
						this.loading = false;
					}
					return res.json();
				})
				.then((js) => {
					this.listShipper = js.data;
				});
				loading(false);
				this.loading = false;
			}
		},
		removeShipper(id) {
			this.forms.shipper = this.forms.shipper.filter((e) => e.id !== id);
		},
		createVoucher() {
			var type = this.forms.save;
			console.log(this.forms);
			this.loading = true;
			this.customSwal.fire({
				html: '<img src="' + this.getImage('voucher') + '" class="swal2-ico d-block mx-auto mb-3" /><div class="h5">Yakin ingin ' + (type == 'draft' ? 'simpan ke draft' : 'aktifkan voucher') + '?</div>',
				showDenyButton: true,
				confirmButtonText: type == 'draft' ? 'Simpan ke draft' : 'Aktifkan',
				denyButtonText: 'Batal'
			}).then((result) => {
				if (result.isConfirmed) {
					this.customSwal.fire({
						html: '<img src="' + this.getImage('ok') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h5">Voucher berhasil ' + (type == 'draft' ? 'simpan ke draft' : 'publish') + '?</div>',
						showConfirmButton: false,
						showCancelButton: true,
						cancelButtonText: 'Oke'
					});
				}
				this.loading = false;
			});
		
		}
	},
	components: {
		CurrencyInput,
		vSelect
	},
	watch: {
		forms: {
			handler(val) {
				if (val.type == 'percentage' && val.percentage != '' && val.nominal > 0) {
					this.forms.resume = 'Diskon ' + parseInt(this.forms.percentage) + '% maksimal Rp ' + formatCurrency(this.forms.nominal);
				} else if (val.type == 'fixed' && val.fixed > 0) {
					this.forms.resume = 'Diskon Rp ' + formatCurrency(this.forms.fixed);
				}

				if (val.quota == 'unlimited') {
					this.forms.limit = null;
					this.errors.limit = false;
				}

				if (val.usage == 'user') {
					this.forms.maximum = 1;
				}

				if (val.minimum == 'none') {
					this.forms.value = null;
				}
			},
			deep: true
		},
		shipper: function (val) {
			this.forms.shipper.push(this.listShipper.filter(function (el) {
				return el.id == val;
			})[0]);
			this.forms.shipper = this.forms.shipper.filter((value, index, self) =>
				index === self.findIndex((t) => (
					t.id === value.id && t.label === value.label
				))
			);
		}
	},
	computed: {
		shipper: function () {
            return this.selectShipper;
        }
	},
	mounted() {
		const e = this.$refs;
		var self = this;

		new Daterangepicker(
			this.$refs.period,
			{
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month"),
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.forms.period_start = d1.format("YYYY-MM-DD");
				self.forms.period_end = d2.format("YYYY-MM-DD");
			}
		);

		authFetch("/shipper/member_type/boscod").then(res => {
			res.json().then((json) => {
				if (json.status) {
					this.listMember = json.data;
				}
			});
		});

		new Daterangepicker(
			this.$refs.join,
			{
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month"),
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.forms.join_start = d1.format("YYYY-MM-DD");
				self.forms.join_end = d2.format("YYYY-MM-DD");
			}
		);

		authFetch(`/order/province`).then((res) => {
			res.json().then((json) => {
				if (json.success) {
					this.listProvince = json.data;
				}
			});
		});

		authFetch(`/dashboard/admin/kurir`).then((res) => {
			res.json().then((json) => {
				if (json.success) {
					this.listCourier = json.data;
				}
			});
		});

		this.customSwal = Swal.mixin({
			customClass: {
				content: 'p-0',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary col font-weight-bold',
				denyButton: 'btn btn-outline-primary col font-weight-bold',
				cancelButton: 'btn btn-primary w-75 font-weight-bold m-auto',
			},
			buttonsStyling: false
		});
	}
};
</script>
<style>
.vs--single .vs__selected-options {
	overflow: hidden;
    white-space: nowrap;
}

.vs--single .vs__selected {
    width: 0;
}
</style>